import { configureStore } from '@reduxjs/toolkit';
import fileReducer from './files.js';
import authReducer from './auth.js';
import sharedFileReducer from './shared_files.js';
import usageLimitsReducer from './usage_limits.js';

export const store = configureStore({
  reducer: {
    usage_limits: usageLimitsReducer,
    auth: authReducer,
    files: fileReducer,
    shared_files: sharedFileReducer
  },
});

