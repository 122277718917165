import React from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import MetamaskLogin from './metamaskLogin';

export default function DropDown(){
  return (
    <div className="w-56 text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-sm bg-white px-4 py-2 text-sm text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            Sign In
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-black"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute -right-8 mt-2 w-56 origin-top-right shadow-md divide-y divide-gray-100 rounded-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 w-full">
            <Menu.Item>
              {/* link to /auth for now */}
              <Link to="/auth">
                <button
                  className={`text-black hover:text-[#1876EE] group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                >
                  Email
                </button>
              </Link>
            </Menu.Item>

            <Menu.Item>
              <MetamaskLogin/>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};
