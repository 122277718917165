import {selectFileShareLinkData, shareFile, deleteSharedFile} from '../../../../../../../redux/files.js';
import {useDispatch, useSelector } from 'react-redux';
import Toastify from 'toastify-js'
import {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

// let domain = process.env.REACT_APP_WEBSITE_URL ? process.env.REACT_APP_WEBSITE_URL : "http://localhost:3000";

export default function ShareFile({id}) {
  let dispatch = useDispatch();
  let linkData = useSelector(selectFileShareLinkData);
  let [loading,setLoading] = useState(false);
  

  const disableLink = async () => {
    setLoading(true);
    await dispatch(deleteSharedFile(id, linkData[0].id));
    setLoading(false);
  }

  
  const createLink = async () => {
    if(loading) return;
    setLoading(true);
    await dispatch(shareFile(id,{type:'link'}));
    setLoading(false);
  }

  const copyLink = () => {
    navigator.clipboard.writeText('https://filepeer.xyz/shared_file/'+linkData[0].id);
    Toastify({text:"Copied",style: {background: "linear-gradient(to right, #198754, #198751"}}).showToast();
  }
  
  return (
    <div className='flex flex-auto items-center align-middle'>
      {linkData.length === 0 ? 
          <div className="mx-auto mt-32">
            {loading ? 
              <ClipLoader/>
              :
              <button className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={createLink.bind(this)}>
                Create Link
              </button>
            }
          </div>
      :
      <>
        <div className="mx-auto d-flex flex-column mt-32">
          <button className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={copyLink.bind(this)}>
            Copy Link
          </button>
        </div>
        <div className="mx-auto d-flex flex-column mt-32">
          {loading ? 
            <ClipLoader/>
            :
            <button className="rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={disableLink.bind(this)}>
              Disable Link
            </button>
          }
        </div>
        </>
      }
    </div>
  )
}