import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';
import Toastify from 'toastify-js'

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

const initialState = {
  file_share_link_data: [],
  file_share_email_data:[],
  file_share_wallet_data:[],
  files: [],
  descDate: true,
  descName: false,
  totalUploaded: 0,
};

export const docSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    sortName: (state) => {
      state.files.sort((a,b)=>{
       if (a.name < b.name) return state.descName ? -1 : 1;
       if (a.name > b.name) return state.descName ? 1 : -1;
       return 0
     });
      state.descName = !state.descName;
    },
    sortDate: (state) => {
      if(state.descDate){
        state.files.sort((a,b)=>{return new Date(a.created_at) - new Date(b.created_at)});
        state.descDate = false;
      }
      else {
        state.files.sort((a,b)=>{return new Date(b.created_at) - new Date(a.created_at)});
        state.descDate = true
      }
    },
    clearDocs: (state, action) => {
      state = initialState;
    },
    appendDoc: (state,action) => {
      if(!state.files) state.files = [action.payload]
      else state.files.unshift(action.payload);
    },
    setTotalUploaded: (state, action) => {

      let showSize = (size) => {
        if(size < 1000){
          return `${(size).toFixed(2)} B`
        }
        else if(size < 1000*1000) {
          return `${(size/1000).toFixed(2)} KB`
        }
        else if(size < 1000*1000*1000) {
          return `${(size/(1000*1000)).toFixed(2)} MB`
        }
        else {
          return `${(size/(1000*1000*1000)).toFixed(2)} GB`
        }
      }

      state.totalUploaded = showSize(action.payload);
    },
    setFiles: (state,action) => {
      if(action.payload.length === 0) {
        state.files = null;
      }
      else state.files = action.payload;
    },
    setFileShareData: (state,action) => {
      state.file_share_link_data = action.payload.filter(({type})=>{
        return type === 'link';
      });
      state.file_share_email_data = action.payload.filter(({type})=>{
        return type === 'email';
      });
      state.file_share_wallet_data = action.payload.filter(({type})=>{
        return type === 'wallet';
      });
    }
  },
});

export const {clearDocs, appendDoc, setFiles, sortName, sortDate, setTotalUploaded, setFileShareData} = docSlice.actions;
export const selectFiles = (state) => state.files.files;
export const selectTotalUploaded = (state) => state.files.totalUploaded;
export const selectFileShareLinkData = (state) => state.files.file_share_link_data;
export const selectFileShareWalletData = (state) => state.files.file_share_wallet_data;
export const selectFileShareEmailData = (state) => state.files.file_share_email_data;

export const getFiles = () => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    let data = await sg.getFiles();
    let totalUploaded = await sg.getTotalFileSizeUploaded();
    dispatch(setFiles(data));
    dispatch(setTotalUploaded(totalUploaded));
    return true
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const uploadFile = (newFile) => async (dispatch, getState) => {
  try {
    dispatch((appendDoc(newFile)));
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    let totalUploaded = await sg.getTotalFileSizeUploaded();
    dispatch(setTotalUploaded(totalUploaded));
  }
  catch(err){
    Toastify({text:err,style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
    console.log({err});
  }
  return;
}

export const retrieveFileShareData = (file_id)  => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    let data = await sg.retrieveFileShareData(file_id);
    dispatch(setFileShareData(data));
  }
  catch(err){
    console.log({err});
  }
}

export const shareFile = (file_id, {email_address, wallet_address, type}) => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    await sg.shareFile(file_id,{email_address,wallet_address,type});
    await dispatch(retrieveFileShareData(file_id));
    return;
  }
  catch(err){
    console.log({err});
    Toastify({text:err,style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
  }
}

export const deleteSharedFile = (file_id, shared_file_id) => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    await sg.deleteSharedFile(shared_file_id);
    await dispatch(retrieveFileShareData(file_id));
    return;
  }
  catch(err){
    console.log({err});
  }
}

export default docSlice.reducer;
