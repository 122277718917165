import FileSaver from "file-saver";
import SelfGuard from "selfguard-client";
import Toastify from "toastify-js";

let domain = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8080";

export default function Document({ id,decrypting, setValue, setDecrypting }) {
  const downloadFile = async () => {
    setValue(0);
    setDecrypting(true);
    try {
      let sg = new SelfGuard(
        window.storage.getItem("api_key"),
        window.storage.getItem("key_pair_type"),
        window.storage.getItem("public_key"),
        window.storage.getItem("private_key"),
        domain
      );
      let file = await sg.decryptSharedFile(id, (err, data) => {
        setValue(data);
      });
      console.log(file);
      FileSaver.saveAs(file, file.name);
    } catch (err) {
      console.log({ err });
      Toastify({
        text: err,
        style: { background: "linear-gradient(to right, #dc3545, #dc3541" },
      }).showToast();
    }
    setDecrypting(false);
  };

  return !decrypting && (
    <button
      className="rounded-md px-4 h-16 text-white bg-[#1876EE]"
      onClick={downloadFile.bind(this)}
    >
      Download
    </button>
  );
}
