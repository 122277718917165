
import {deleteSharedFile} from '../../../../../../../redux/files.js';
import {useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import {useDispatch} from 'react-redux';

export default function ShareFile({file_id,wallet}) {
  let {wallet_address,id} = wallet;
  let [loading,setLoading] = useState(false);
  let dispatch = useDispatch();
  
  const disableLink = async () => {
    setLoading(true);
    await dispatch(deleteSharedFile(file_id, id));
    setLoading(false);
  }

  //return react component that allows a user to type in a wallet_address in an input and clicking submit in the same line
  return (
    <li key={id} className="py-4">
      <div className="flex items-center space-x-4">
        <div className="min-w-100 flex-1">
          <p className="truncate text-sm font-medium text-gray-900">{wallet_address}</p>
        </div>
        <div className='flex justify-center w-full'>
          {loading ? 
            <ClipLoader/>
            :
            <button
              onClick={disableLink.bind(this)}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-1 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
            >
              Disable
            </button>
          }
        </div>
      </div>
    </li>

  )
}