import moment from 'moment';
import DownloadFile from './DownloadFile';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function File({file,index}) {
  let {name, created_at, size, id} = file;
  
  //function that takes in a number and returns the respective kb, mb, or gb amount
  let showSize = () => {
    if(size < 1000){
      return `${(size).toFixed(2)} B`
    }
    else if(size < 1000*1000) {
      return `${(size/1000).toFixed(2)} KB`
    }
    else if(size < 1000*1000*1000) {
      return `${(size/(1000*1000)).toFixed(2)} MB`
    }
    else {
      return `${(size/(1000*1000*1000)).toFixed(2)} GB`
    }
  }


  let showDate = () => {
    //if created at was within the last day just show the time with am/pm, else show the date and time
    if(moment(created_at).isSame(moment(),'day')){
      return moment(created_at).format('hh:mm A')
    }
    else {
      return moment(created_at).format('MM/DD/YY')
    }
  }


  return (
    <tr key={id}>
        <td
          className={classNames(
            index === 0 ? '' : 'border-t border-transparent',
            'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'
          )}
        >
        <div className="font-medium text-gray-900 max-w-sm text-truncate">
          {name}
        </div>
        
        <div className="mt-1 flex flex-col text-gray-500 lg:hidden">
          <span>
            {showSize()}
          </span>
          <span className="hidden sm:inline"> </span>
          <span>{showDate()}</span>
        </div>

        {index !== 0 ? <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" /> : null}

      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-gray-200',
          'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
        )}
      >
        {showSize()}
      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-gray-200',
          'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
        )}
      >
        {showDate()}
      </td>
      <td
        className={classNames(
          index === 0 ? '' : 'border-t border-transparent',
          'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
        )}
      >
        <DownloadFile id={id}/>
        {index !== 0 ? <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" /> : null}
        
      </td>
    </tr>
  )
}
