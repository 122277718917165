import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

const initialState = () => {
  return {
    loaded:false,
    limits : null
  }
};

export const docSlice = createSlice({
  name: 'usage_limits',
  initialState: initialState(),
  reducers: {
    clearLimits: (state) => {
      // Set all state values to their initial values
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setLimits: (state, action) => {
      state.loaded = true;
      state.limits = action.payload;
    }
  },
});

export const {clearLimits, setLimits} = docSlice.actions;
export const selectLimits = (state) => state.usage_limits.limits;

export const getUsageLimits = () => async (dispatch, getState) => {
  let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
  let limits = await sg.getUsageLimits();
  dispatch(setLimits(limits));
}

export default docSlice.reducer;
