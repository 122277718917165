import { createSlice } from '@reduxjs/toolkit';
import SelfGuard from 'selfguard-client';

let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

const initialState = {
  shared_files: [],
  file_by_link: null,
};

export const docSlice = createSlice({
  name: 'shared_files',
  initialState,
  reducers: {
    setFileByLink : (state, action) => {
      state.file_by_link = action.payload;
    },
    clearShared: (state, action) => {
      state = initialState;
    },
    setSharedFiles: (state,action) => {
      if(action.payload.length === 0) {
        state.shared_files = null;
      }
      else state.shared_files = action.payload;
    },
  },
});

export const {clearShared, setFileByLink, setSharedFiles} = docSlice.actions;
export const selectSharedFiles = (state) => state.shared_files.shared_files;
export const selectFileByLink = (state) => state.shared_files.file_by_link;

export const getSharedFiles = () => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(window.storage.getItem('api_key'),window.storage.getItem('key_pair_type'),window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
    let data = await sg.retrieveSharedFiles();
    data = data.map((data)=>{
      return {
        ...data,
        name: data.file.name,
        size: data.file.size,
        type: data.file.type,
        document_hash: data.file.document_hash
      }
    }).reverse();
    dispatch(setSharedFiles(data));
    return true
  }
  catch(err){
    console.log({err});
    throw new Error(err);
  }
};

export const getFileByLink = (id, history ) => async (dispatch, getState) => {
  try {
    let sg = new SelfGuard(null,null,null,null,domain);
    let data = await sg.retrieveFileByLink(id);
    
    let file = {
      name: data.file.name,
      created_at: data.created_at,
      size: data.file.size,
      type: data.file.type,
      id
    }
    dispatch(setFileByLink(file))
    return true;
  }
  catch(err){
    console.log(err);
    console.log(history('/'));
  }
}

export default docSlice.reducer;
