import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../redux/files";
import { getSharedFiles } from "../../redux/shared_files";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import {useEthers} from '@usedapp/core'
import Login from './Login/index.js';
import {clearAuth, selectAPIKey} from '../../redux/auth';
import { getUsageLimits } from '../../redux/usage_limits';

import Main from '../Main';

export default function Home() {
  const { account, deactivate } = useEthers();
  let dispatch = useDispatch();
  let session = useSessionContext();
  let [called, setCalled] = useState(false);
  let api_key = useSelector(selectAPIKey);

  async function fetchData() {
    if (
      ((session && session.doesSessionExist) || (account && api_key)) &&
      !called
    ) {
      try {
        await dispatch(getFiles())
        dispatch(getSharedFiles())
        dispatch(getUsageLimits());
        setCalled(true);
      } catch (err) {
        console.log({ err });
        setCalled(true);
        dispatch(clearAuth());
        if (account) deactivate();
        else await signOut();
      }
    }
  }

  useEffect(() => {
    fetchData();
  });

  let loggedIn = session.doesSessionExist || (account && api_key);

  return (
    <>
    {loggedIn ? <Main /> :
      <div className="w-full relative h-screen overflow-hidden">
        <div className="w-full bg-self h-full absolute z-10" />

        <div className="relative z-20 w-full py-8 items-center flex justify-between px-24">
          <a href="https://filepeer.xyz/">
            <img src="/android.png" alt="" className="w-32" />
          </a>
          <Login />
        </div>

        <div className="relative z-10 w-full pt-48 items-center flex flex-col">
          <h1 className="mt-8 text-white font-medium text-center text-6xl" style={{lineHeight:'5rem'}}>
            End-To-End Encrypted <br/> File Storage & Transfers
          </h1>
        </div>
        
      </div>
    }
    </>
  );
}
