import {useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux';
import { ethers } from 'ethers';
import { SiweMessage } from 'siwe';
import {useEthers} from '@usedapp/core'
import {clearAuth, setAuth} from '../../../redux/auth';
import {getFiles, clearDocs} from '../../../redux/files';
import {getSharedFiles, clearShared} from '../../../redux/shared_files';
import {getUsageLimits, clearLimits} from '../../../redux/usage_limits';

const domain = window.location.host;
const origin = window.location.origin;

let BACKEND_ADDR = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function Home() {
  const { account, activateBrowserWallet} = useEthers()
  let dispatch = useDispatch();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    },[value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  }
  const prevAccount = usePrevious(account)

  useEffect(()=>{
    if(prevAccount !== account && prevAccount !== undefined && account !== undefined){
      dispatch(clearAuth());
      dispatch(clearDocs());
      dispatch(clearShared());
      dispatch(clearLimits());
    }
    if(prevAccount && !account){
      dispatch(clearAuth());
      dispatch(clearDocs());
      dispatch(clearShared());
      dispatch(clearLimits());
    }
  },[dispatch,account,prevAccount])

  async function createSiweMessage(address, statement) {
      const res = await fetch(`${BACKEND_ADDR}/nonce`);
      const message = new SiweMessage({
          domain,
          address,
          statement,
          uri: origin,
          version: '1',
          chainId: '1',
          nonce: await res.text()
      });
      return message.prepareMessage();
  }

  async function signInWithEthereum() {
    await activateBrowserWallet();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    let message = await createSiweMessage(
        await signer.getAddress(),
        'Sign in with Ethereum to the app.'
    );
    let signature = await signer.signMessage(message);
    const res = await fetch(`${BACKEND_ADDR}/createAPIKeyMetamask`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, signature }),
    });
    let key = await res.json();
    dispatch(setAuth({api_key: key, key_pair_type:'metamask'}));
    dispatch(getFiles())
    dispatch(getSharedFiles())
    dispatch(getUsageLimits());
  }

  return (
    <button
    onClick={signInWithEthereum}
    className={`text-black hover:text-[#1876EE] group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
    >
      Wallet
    </button>
  );
}

