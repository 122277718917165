import {selectFileShareWalletData} from  "../../../../../../../redux/files.js";
import {useSelector} from "react-redux";
import Wallet from './Wallet.js';
import CreateSharedWallet from './CreateSharedWallet.js';

export default function ShareFile({id}) {
  let walletShares = useSelector(selectFileShareWalletData);

  //return react component that allows a user to type in a wallet_address in an input and clicking submit in the same line
  return (
    <div className='row'>
      <div className='col-12' style={{height:'150px',overflow:'scroll'}}>
        <ul className="-my-5 divide-y divide-gray-200">
          <CreateSharedWallet id={id}/>
          {walletShares.map((walletShare,i)=>(
            <Wallet wallet={walletShare} key={i} file_id={id}/>
          ))}
        </ul>
        <div>
          {walletShares.length === 0 &&
            <div className='text-center mt-8 border-t border-black py-4'>
              <p>Type in a wallet address above to share this file with them</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}