import FileSaver from "file-saver";
import SelfGuard from "selfguard-client";
import Toastify from "toastify-js";
import React, {useState} from 'react'

let domain = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8080";

export default function Document({ id }) {
  let [decrypting, setDecrypting] = useState(false);
  let [value, setValue] = useState(0);

  const downloadFile = async () => {
    setValue(0);
    setDecrypting(true);
    try {
      let sg = new SelfGuard(window.storage.getItem('api_key'), window.storage.getItem('key_pair_type'), window.storage.getItem('public_key'),window.storage.getItem('private_key'),domain);
      let file = await sg.decryptSharedFile(id,(err,data) => {
        setValue(data);
      });
      FileSaver.saveAs(file,file.name);
    }
    catch(err){
      console.log({err});
      Toastify({text:err,style: {background: "linear-gradient(to right, #dc3545, #dc3541"}}).showToast();
    }
    setDecrypting(false);
  }

  return (
    <>
    {decrypting ?
      <div className="w-full mt-4 bg-gray-200 dark:bg-gray-700 mb-4"> 
        <div className="absolute pt-2 pb-2 text-xs font-medium text-blue-100 text-center p-0.5 leading-none overflow-x-auto"> Downloading {value}%</div>
        <div className="bg-blue-600 pt-2 pb-2 text-xs font-medium text-blue-100 text-center p-0.5 leading-none overflow-x-auto" style={{height:'30px',width: `${parseInt(value)+5}%`}}></div>
      </div>
      :
      <button
      className="mb-4:sm inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      onClick={downloadFile.bind(this)}
    >
      Download
    </button>
    }
    </>
  );
}
