import React, {useState} from 'react'
import Modal from './modal.js';
import {retrieveFileShareData} from '../../../../../../redux/files.js';
import {useDispatch } from 'react-redux';

export default function Document({ id,name}) {
  const [open, setOpen] = useState(false);
  let dispatch = useDispatch();

  let showModal = async () => {
    await dispatch((retrieveFileShareData(id)))
    setOpen(true);
  }

  return (
    <>
      <button
      className="mb-4:sm ml-2 mt-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      onClick={showModal}
    >
      Share
    </button>
    <Modal open={open} setOpen={setOpen} file_id={id} title={'Share File - ' + name} fields={[]} id='shareModal' closeId={'shareModal'}/>
    </>
  );
}
