import { useState } from "react";
import {shareFile} from  "../../../../../../../redux/files.js";
import {useDispatch} from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

export default function ShareFile({id}) {
  let [wallet_address, setWalletAddress] = useState(null);
  let dispatch = useDispatch();
  let [loading,setLoading] = useState(false);

  const onChange = (e) => {
    setWalletAddress(e.target.value);
  }

  const addWallet = async (e) => {
    if(e && e.preventDefault) e.preventDefault();
    setLoading(true);
    await dispatch(shareFile(id, {wallet_address, type: 'wallet'}));
    setLoading(false);
  }

  //return react component that allows a user to type in a wallet_address in an input and clicking submit in the same line
  return (
    <li key={id} className="py-4">
      <div className="flex items-center space-x-4" style={{height:'50px'}}>
        <div className="min-w-100 flex-1">
          <form onSubmit={addWallet.bind(this)} style={{width:'340px'}}>
            <input type="text" className="block w-full px-2 py-2 text-gray-900 placeholder-gray-500 sm:text-sm w-full border:none border-radius:0" placeholder="Wallet Address" onChange={onChange.bind(this)} />
          </form>
        </div>
        <div className='flex justify-center w-full'>
          {loading ? 
            <ClipLoader/>
            :
            <button
              onClick={addWallet.bind(this)}
              className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-1 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
            >
              Share
            </button>
          }
        </div>
      </div>
    </li>
  )
}