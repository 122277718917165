import React, { useState } from "react";
import moment from "moment";
import DownloadFile from "./DownloadFile.js";
import FileName from "./FileName.js";
import Loading from "./Loading.js";
import { useSelector } from "react-redux";
import { selectFileByLink } from "../../../redux/shared_files";

export default function File() {
  let file = useSelector(selectFileByLink);
  let { name, created_at, size, type, id } = file ? file : {};

  let [decrypting, setDecrypting] = useState(false);
  let [value, setValue] = useState(0);

  let showSize = (size) => {
    if (size < 1000) {
      return `${size.toFixed(2)} B`;
    } else if (size < 1000 * 1000) {
      return `${(size / 1000).toFixed(2)} KB`;
    } else if (size < 1000 * 1000 * 1000) {
      return `${(size / (1000 * 1000)).toFixed(2)} MB`;
    } else {
      return `${(size / (1000 * 1000 * 1000)).toFixed(2)} GB`;
    }
  };

  return (
    file && (
      <div className="w-full h-screen flex justify-center items-center">
        <img
          src="/bg.jpg"
          alt=""
          className="absolute h-full object-cover w-full"
        />
        <div className="absolute pt-8 top-0 w-full">
          <a href='https://filepeer.xyz'>
          <img src="/android.png" alt="" className="w-32 mx-auto" />
          </a>
        </div>
        <div className="bg-white relative shadow-nav p-8 flex flex-col rounded-md">
          {/* <h4 className="text-black/50"> File Shared By Link</h4> */}
          <div className="flex lg:flex-row flex-col items-start space-y-8 lg:space-y-0 lg:items-center">
            <div className="w-16 flex items-center justify-center h-16 mr-8 rounded-md bg-[#1876EE50]">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="stroke-[#1876EE]"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H8.31672C9.11834 2 9.51916 2 9.88103 2.09146C10.4791 2.24262 11.016 2.57444 11.4186 3.04174C11.6623 3.32451 11.8415 3.683 12.2 4.4V4.4C12.439 4.878 12.5585 5.11699 12.7209 5.30551C12.9894 5.61704 13.3473 5.83825 13.746 5.93902C13.9872 6 14.2544 6 14.7889 6H15.6C17.8402 6 18.9603 6 19.816 6.43597C20.5686 6.81947 21.1805 7.43139 21.564 8.18404C22 9.03968 22 10.1598 22 12.4V15.6C22 17.8402 22 18.9603 21.564 19.816C21.1805 20.5686 20.5686 21.1805 19.816 21.564C18.9603 22 17.8402 22 15.6 22H8.4C6.15979 22 5.03968 22 4.18404 21.564C3.43139 21.1805 2.81947 20.5686 2.43597 19.816C2 18.9603 2 17.8402 2 15.6V6.8Z"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col mr-8">
              <h5 className="text-xl">
                <FileName name={name} type={type} id={id} />
              </h5>
              <div className="flex space-x-4 text-black/50">
                <p>{showSize(size)}</p>
                <p>{moment(created_at).format("MM/DD/YY")}</p>
              </div>
            </div>

            <div className="flex justify-end">
              <DownloadFile
                id={id}
                setDecrypting={setDecrypting}
                decrypting={decrypting}
                setValue={setValue}
              />
            </div>
          </div>

          {decrypting && <Loading value={value} />}
        </div>
      </div>
    )
  );
}
