import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getFileByLink } from "../../redux/shared_files";
import { useNavigate } from "react-router-dom";
import File from "./File";

// const domain = window.location.host;
// const origin = window.location.origin;

// let BACKEND_ADDR = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

export default function Home() {
  console.log("Home")
  let dispatch = useDispatch();
  const params = useParams();
  let history = useNavigate();

  async function fetchData() {
    await dispatch(getFileByLink(params.id, history));
  }

  useEffect(() => {
    fetchData();
  });

  return (
    <div>
      <File />
    </div>
  );
}
