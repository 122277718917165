import React, { useState } from "react";
import SharedWallet from './SharedWallet';
import SharedEmail from './SharedEmail';
import SharedLink from './SharedLink';

const tabs = [
  { name: 'Link', href: '#' },
  { name: 'Wallet', href: '#' },
  { name: 'Email', href: '#'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export  function LinksTabs({id}) {
  let [curr,setCurr] = useState("Link");

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => curr === tab.name).name}
          onChange={(e)=>console.log(e)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block w-full">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                onClick={()=>setCurr(tab.name)}
                style={{width:'33.33%',textAlign:'center'}}
                className={classNames(
                  curr === tab.name
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={curr === tab.name ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-4" style={{height:'300px'}}>
        {curr === "Link" && <SharedLink id={id} />}
        {curr === "Wallet" && <SharedWallet id={id} />}
        {curr === "Email" && <SharedEmail id={id} />}
      </div>
    </div>
  )
}