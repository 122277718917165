import { createSlice } from '@reduxjs/toolkit';

const initialState = () => {
  return {
    api_key: window.localStorage.api_key,
    public_key: window.localStorage.public_key,
    private_key: window.localStorage.private_key,
    key_pair_type: window.localStorage.key_pair_type,
  }
};

export const docSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  reducers: {
    clearAuthKeys: (state) => {
      window.storage.removeItem('api_key');
      window.storage.removeItem('key_pair_type');
      window.storage.removeItem('public_key');
      window.storage.removeItem('private_key');
      window.storage.removeItem('user');
      state.api_key = null;
      state.private_key = null;
      state.public_key = null;
      state.key_pair_type = null;
    },
    setAuthKeys: (state, action) => {
      window.storage.setItem("api_key", action.payload.api_key);
      window.storage.setItem("private_key",action.payload.private_key);
      window.storage.setItem("public_key", action.payload.public_key);
      window.storage.setItem("key_pair_type", action.payload.key_pair_type);
      window.storage.setItem('user', window.ethereum.selectedAddress);
      state.api_key = action.payload.api_key;
      state.private_key = action.payload.private_key;
      state.public_key = action.payload.public_key;
      state.key_pair_type = action.payload.key_pair_type;
    }
  },
});

export const {clearAuthKeys, setAuthKeys} = docSlice.actions;
export const selectAPIKey = (state) => state.auth.api_key;

export const clearAuth = () => async (dispatch, getState) => {
  dispatch(clearAuthKeys());
}

export const setAuth = (payload) => async (dispatch, getState) => {
  dispatch(setAuthKeys(payload));
}

export default docSlice.reducer;
