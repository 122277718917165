import {style} from './style';
import React from 'react';
// import {signIn, signUp} from './auth.js';

//display of the auth component
export function Page({ DefaultComponent, ...props }) {
  return (
      <div className=''style={{marginTop:'20px'}}>
          <div className='text-center'>
            <div className="navbar-brand vertical text-center" style={{justifyContent:'center'}}>
              <img src="/android.svg" width="300" className="d-inline-block" alt=""/>
            </div>
          </div>
          <DefaultComponent {...props} />
          <div className='text-center'>
            <br/>
            {/* <a href='#'>
              Forgot your password? Click here.
            </a> */}
          </div>
      </div>
  );
}

export function SuperTokensOptions(signIn, signUp){
  return {
    getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
            if (context.redirectToPath !== undefined) {
                return context.redirectToPath;
            }
            return "/";
        }
        return undefined;
    },
    signInAndUpFeature: {
        defaultToSignUp: true
    },
    useShadowDom: false,
      override: {
        components: {
            EmailPasswordSignIn_Override: ({ DefaultComponent, ...props }) => {
              return Page({ DefaultComponent, ...props })
            },
            EmailPasswordSignUp_Override: ({ DefaultComponent, ...props }) => {
              return Page({ DefaultComponent, ...props })
            },
        },
        functions: (originalImplementation) => {
          return {
              ...originalImplementation,
              signIn: async function (input){
                let status = await originalImplementation.signIn(input);
                if(status.status === "OK"){
                  let password = input.formFields[1].value;
                  let email = input.formFields[0].value;
                  await signIn(email, password);
                }
                return status;
              },
              signUp: async function (input) {
                let status = await originalImplementation.signUp(input);
                if(status.status === "OK"){
                  let password = input.formFields[1].value;
                  let email = input.formFields[0].value;
                  await signUp(email, password);
                }
                return status;
              }
          }
        }
      },
      emailVerificationFeature: {
          mode: "REQUIRED",
      },
      style
  }
}

export function getApiDomain() {
  const apiPort = process.env.REACT_APP_API_PORT || 8080;
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
  return apiUrl;
}

export function getWebsiteDomain() {
  const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
  return websiteUrl;
}