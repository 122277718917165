import React, { useState, useEffect } from "react";

export default function FileName({ name, type, id }) {
  let [width, setWidth] = useState(
    document.getElementById(`${id}name`)
      ? document.getElementById(`${id}name`).offsetWidth - 100
      : 400
  );
  function handleResize() {
    setWidth(
      document.getElementById(`${id}name`)
        ? document.getElementById(`${id}name`).offsetWidth - 45
        : 500
    );
  }
  window.addEventListener("resize", handleResize);
  useEffect(() => {
    setWidth(
      document.getElementById(`${id}name`)
        ? document.getElementById(`${id}name`).offsetWidth - 45
        : 500
    );
  }, [id]);
  console.log(width);
  return (
    <div style={{width:'400px'}}>
      <h6 className="truncate">{name}</h6>
    </div>
  );
}
