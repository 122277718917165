import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../../redux/files";
import SelfGuard from "selfguard-client";
import Toastify from "toastify-js";
let domain = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8080";

export default function UploadFile(props) {
  let dispatch = useDispatch();
  let [uploading, setUpload] = useState(false);
  let [value, setValue] = useState(0);
  let [name, setName] = useState("hello");

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setValue(0);
      setUpload(true);
      //should be a promise.all
      for (let file of acceptedFiles) {
        try {
          let sg = new SelfGuard(
            window.storage.getItem("api_key"),
            window.storage.getItem("key_pair_type"),
            window.storage.getItem("public_key"),
            window.storage.getItem("private_key"),
            domain
          );
          setName(file.name);
          let newFile = await sg.encryptFile(
            file,
            (_, data) => {
              if (data) setValue(data);
            },
            "raw_ipfs_upload"
          );
          dispatch(uploadFile(newFile));
          setUpload(false);
        } catch (err) {
          console.log({ err });
          Toastify({
            text: err,
            style: { background: "linear-gradient(to right, #dc3545, #dc3541" },
          }).showToast();
        }
      }
      setName(null);
      setUpload(false);
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="xl:row-span-3 xl:col-span-2" id="uploadFiles">
      {!uploading ? (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <button className="w-full bg-self flex justify-center items-center text-white xl:row-span-1 xl:col-span-2 p-8 rounded-sm hover:bg-blue-700">
            Upload File
          </button>
        </div>
      ) : (
        <button disabled className="w-full bg-slate-700 flex justify-center items-center text-white xl:row-span-1 xl:col-span-2 p-8 rounded-sm">
            Uploading {name} - {value}%
          </button>
      )}
    </div>
  );
}
