import SuperTokens, {
  SuperTokensWrapper,
  getSuperTokensRoutesForReactRouterDom,
} from "supertokens-auth-react";
import Home from "./components/Home";
import FilePage from "./components/FilePage";
import Footer from './components/Home/footer';
import {
  Routes,
  Navigate,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import { Mainnet, DAppProvider } from "@usedapp/core";
import {
  getApiDomain,
  getWebsiteDomain,
  SuperTokensOptions,
} from "./components/SuperTokens/index.js";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { signIn, signUp } from "./auth";

SuperTokens.init({
  appInfo: {
    appName: "SuperTokens Demo App", // TODO: Your app name
    apiDomain: getApiDomain(), // TODO: Change to your app's API domain
    websiteDomain: getWebsiteDomain(), // TODO: Change to your app's website domain
  },
  recipeList: [
    EmailPassword.init(SuperTokensOptions(signIn, signUp)),
    Session.init(),
  ],
});

const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://eth-mainnet.g.alchemy.com/v2/v-sDFwyXtuxgVhVAZ3UQk-eX54g2rJZq",
  },
};

function App() {
  return (
    <SuperTokensWrapper>
      <div className="App">
        <Router>
          <div className="fill">
            <Routes>
              {getSuperTokensRoutesForReactRouterDom(
                require("react-router-dom")
              )}
              <Route
                path="/"
                element={
                  <DAppProvider config={config}>
                    <div style={{ minHeight: "calc(100vh - 120px)" }}>
                     <Home />
                    </div>
                    <Footer />
                  </DAppProvider>
                }
              />
              <Route
                path="/shared_file/:id"
                element={
                  <>
                  <div style={{ minHeight: "calc(100vh - 120px)" }}>
                    <FilePage />
                  </div>
                    <Footer />
                  </>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </div>
    </SuperTokensWrapper>
  );
}

export default App;
