import React, {useState, useEffect} from "react";
import UploadFile from './Upload_File';
import YourFiles from './Your_Files';
import FilesSharedWithYou from './Files_Shared_With_You';
import { getFiles } from "../../../redux/files";
import { getSharedFiles } from "../../../redux/shared_files";
import { useDispatch } from "react-redux";

const FilesDashboard = () => {
  let dispatch = useDispatch();
  let [called, setCalled] = useState(false);

  async function fetchData() {
    if(!called){
      try {
        await dispatch(getFiles());
        dispatch(getSharedFiles());
        setCalled(true);
      } catch (err) {
        console.log({ err });
        setCalled(true);
      }
    }
  }

  useEffect(() => {
    fetchData();
  });

  return (
    <>
      <div className="grid gap-4 grid-cols-1">
        <UploadFile/>
        <YourFiles/>
        <FilesSharedWithYou/>
      </div>
    </>
  );
};

export default FilesDashboard;
