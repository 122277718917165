import SelfGuard from 'selfguard-client';
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
let domain = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080"

async function createAPIKey(){
  try {
    Session.addAxiosInterceptors(axios);
    let result = await axios.post(domain + "/createAPIKey");
    return result.data;
  }
  catch(err){
    console.log({err});
  }
}

async function retrieveAPIKey(){
  try {
    Session.addAxiosInterceptors(axios);
    let result = await axios.post(domain + "/retrieveAPIKey");
    return result.data;
  }
  catch(err){
    console.log({err});
  }
}

async function createPubKey(api_key, password){
  let sg = new SelfGuard(api_key, null, null, null, domain); //Setup Selfguard Instnace
  let key_pair = sg.createKeyPair('ecdsa'); //Generate Key Pair
  console.log({key_pair});
  await sg.uploadKeyPair(key_pair, password); //Upload Key Pair
  window.storage.setItem("private_key",key_pair.private_key);
  window.storage.setItem("public_key",key_pair.public_key);
  window.storage.setItem("key_pair_type",'ecdsa');
  return;
}

export async function signIn(email, password){
  try {
    console.log({email, password});
    let api_key = await retrieveAPIKey(); //get api key
    window.storage.setItem("api_key", api_key);
    let sg = new SelfGuard(api_key, null, null, null, domain); //setup Selfguard Instnace
    let keys = await sg.getKeyPairs();
    keys = keys.filter(({type})=> {
      return type === 'ecdsa'
    });
    if(keys.length === 0) {
      await createPubKey(api_key, password);
    }
    else {
      let private_key = sg.decryptWithPassword(keys[0].encrypted_private_key,password);
      window.storage.setItem("private_key",private_key);
      window.storage.setItem("public_key", keys[0].public_key);
      window.storage.setItem("key_pair_type", 'ecdsa');
      window.storage.setItem('user', email);
    }
  }
  catch(err){
    console.log({err});
  }
  return;
}

export async function signUp(email, password) {
  try {
    let api_key = await createAPIKey(); //Create API Key
    window.storage.setItem("api_key", api_key);
    window.storage.setItem('user', email);
    await createPubKey(api_key, password);
  }
  catch(err){
    console.log({err});
  }
  return;
}