export default function Loading({value}) {
  let width = value === 100 ? 100 : value + 5;
  return (
    <div className='flex' style={{marginBottom:'10px',marginTop:'10px'}}>
      <div className='col-12 vertical center' style={{marginBottom:'5px'}}>
        <p className='text-center' style={{margin:0,color:'green'}}> Retrieving & Decrypting File Shards...</p>
      </div>
      {/* Loading Bar */}
      <div className='col-12'>
        <div className="progress">
          <div style={{width:`${width}%`,minWidth:'5%'}} className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow={width} aria-valuemin="0" aria-valuemax="100"><b>{value}%</b></div>
        </div>
      </div>
    </div>
  )
}