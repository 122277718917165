import FilePeer from "./FilePeer";
import Header from './header';

export default function Home() {
  return (
    <div className="min-h-full bg-slate-100">
      <Header />
      <div className="py-10">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
              File Peer
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0">
              <div className="w-full flex flex-col">
                <FilePeer />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

